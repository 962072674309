#img-root:hover{
  animation-duration: 3s;
  box-shadow: var(--box-shadow);
  transform: scale(1.05);
  filter: brightness(1);
}

#iconRoot {
  filter: brightness(0.8);
  -webkit-transition: all 0.3s ease;
 -moz-transition: all 0.3s ease;
 -o-transition: all 0.3s ease;
 transition: all 0.3s ease;
}

#iconRootDark {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    filter: brightness(0.97);
}

#img-root:hover ~ #iconRoot {
transform: scale(0.95) translateY(8px);
    filter: brightness(1);
}

#img-root-dark:hover ~ #iconRootDark {
    transform: scale(0.95) translateY(8px);
    filter: brightness(1);
}

#img-root {
  -webkit-transition: all 0.3s ease;
 -moz-transition: all 0.3s ease;
 -o-transition: all 0.3s ease;
 transition: all 0.3s ease;
  filter: brightness(0.8);
  margin: 24px auto;
}


#img-root-dark {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 24px auto;
    filter: brightness(0.97);
}

#img-root-dark:hover{
    animation-duration: 3s;
    box-shadow: var(--box-shadow);
    transform: scale(1.05);
    filter: brightness(1);
}




.root {
  maxWidth: 280px;

  position: 'relative';
}
