.gridRoot {
  flex-grow: 1;
}

#label_text {

  color: #333;
  font-weight: 100;
}

#gridMain {
  width: 90%;
  margin: 12px auto;
}

.gridItem {
  padding: 16px;
   text-align: 'center';
}

#row-git {
  margin-top: 60px;
}

#gridItemId {
  padding-bottom: 50px;
}

#footer-apps {
  width: 100%;
  height: 60px;
}

a, a:link, a:visited {
  color: #222222;
  text-decoration: none;
}

.flag_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dream_flag {
  width: 30px;
  margin: 16px 0 50px 0;
  height: 18px;
}