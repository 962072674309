@media only screen and (max-width: 340px){
  #appView_gallery {
    width: 100%;
  }
}

@media only screen and (max-width: 792px){
  #appView_gallery {
    width: 100%;
  }
}

@media only screen and (max-width: 1910px){
  #appView_gallery {
    width: 90%;
    margin: 48px auto;
  }
}

#card-root:hover {
  animation-duration: 3s;
  box-shadow: var(--box-shadow-sm);
  transform: scale(1.05);
}

#button-play-market {
  width: 30px;
  height: 30px;
}

#card-root {
  -webkit-transition: all 0.3s ease;
 -moz-transition: all 0.3s ease;
 -o-transition: all 0.3s ease;
 transition: all 0.3s ease;
 width: 100px;
 height: 100px;
}

#view-margins {
  margin-left: 0px;
  margin-right: 0px;
}

#card-media {
  width: 100px;
  height: 100px;
}

#view-gallery {


  box-shadow: var(--box-shadow);
}

#view-gallery-div {
  margin: 36px auto 72px auto;
}

#btn-badge {
  margin-left: 8px;
}

#view-screentext {
  margin-top: 80px;
  color: #333;
  margin-bottom: 24px;
}

#view-row {
  margin-top: 16px;
}

#view-desc {
  margin-top: 36px;
  margin-left: 8px;
  margin-right: 8px;
}
