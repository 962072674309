

/* Global Classes */

#root {
    height: 100%;
}

.title-text{
    /*font: normal 40px/ 72px var(--roboto);*/
}

.text-uppercase{
    text-transform: uppercase;
}

/* Buttons */

.button{
    padding: .8rem 2.2rem;
    font: normal 500 16px/20px var(--roboto);
    position: relative;
    border: 3px solid transparent;
    border-radius: 4px;
}

.button.primary-button{
    background: var(--gradient-color);
    background-clip: padding-box;
    color: whitesmoke;
    transition: background .6s ease;
    box-shadow: var(--box-shadow);
}

.button.primary-button:hover{
    background: whitesmoke;
    background-clip: padding-box;
    color: black;
}

.button.primary-button::after, .button.secondary-button::after{
    position: absolute;
    top: -2px; left: -2px;
    bottom: -2px;  right: -2px;
    background: var(--gradient-color);
    content: ' ';
    z-index: -1;
    border-radius: 4px;
}

.button.secondary-button{
    background: white;
    background-clip: padding-box;
    transition: background .6s ease;
}

.button.secondary-button:hover{
    background: var(--gradient-color);
    color: whitesmoke;
    box-shadow: var(--box-shadow);
}

p.para{
    color: var(--text-color);
    font: normal 500 16px/ 25px var(--roboto);
}

.font-roboto{
    font-family: var(--roboto);
}

button:focus,
button:active
button:hover
.btn:active,
.btn.active{
    outline: 0px !important;
    box-shadow: none !important;
}

.title-h1{
    font: normal bold 39px/ 40px var(--roboto);
}

#guides {
    font: normal 39px/ 40px var(--roboto);
}

.navbar_fixed{
    position: fixed;
    width: 100%;
    top: -1px;
    left: 0; right: 0;
    background: #ffffff;
    box-shadow: var(--box-shadow);
    z-index: 999;
    transition: background 1s ease;
}
