/* start navigation */

.header_area .main-menu .navbar .navbar-brand{
    padding: 0 0 0 5rem;
}

.header_area .main-menu .navbar{
    padding: 1rem 20rem ;
}

.header_area .main-menu .nav-item .nav-link{
    font: normal 500 15px/2px var(--roboto);
    text-transform: uppercase;
    padding: 1.7rem;
    color: var(--title-color);
}

.header_area .main-menu .navbar-nav .active a{
    background: var(--gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.header_area .main-menu .navbar-nav a:hover{
    background: var(--gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#navbar-id {
  /*background: var(--gradient-color);*/
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/
  color: #333;
  letter-spacing: .2rem;
  font-weight: 600;
}

#navbar-icon {
  color: #4458dc;
  -webkit-transition: all 0.3s ease;
 -moz-transition: all 0.3s ease;
 -o-transition: all 0.3s ease;
 transition: all 0.3s ease;
    margin-right: 5rem;
}

#navbar-icon:hover {
  color: #4458dc;
  transform: scale(1.2);
}

@media only screen and (max-width: 768px){
    #navbar-icon {
        margin-right: 0;
    }

    .header_area .main-menu .navbar .navbar-brand{
        padding: 0;
    }
}